import React from "react";
import { ChevronDownIcon, ChevronRightIcon, ExternalLinkIcon } from "./Icons";

const thesisText = (
  <div>
    Computation fluid simulations are difficult. Deep learning offers a powerful
    abstraction that can simplify and expedite fluid simulations, making them
    more accessible.
  </div>
);

const thesisBulletList = [
  "Developed the world's first geometry-agnostic neural fluid simulation framework built on top of the Ansys simulation suite.",
  "Created an end-to-end automated simulation pipeline in Python.",
  "Conducted experiments with geometric deep learning models in PyTorch and Optuna.",
];

const cadFeatureDetectionText = (
  <>
    3D Applications involving BREP data require different geometry
    representations for the UI and ML model. This project was an exercise in
    manipulating geometry data, and also provided a means to learn new frameworks and
    setup infra beyond the vanilla use cases.
  </>
);

const cadFeatureDetectionBulletList = [
  "The frontend consisted of a React/Typescript App that used React-Three-Fiber for Three.js rendering.",
  "File conversion was done on the backend using PythonOCC and FastAPI, deployed on a Lambda using a Dockerfile.",
  "Inference was executed on AWS SageMaker using a UVNet implementation trained on the MFCAD dataset.",
];

const seniorDesignText = (
  <>
    Scripps Institute of Oceanography requested an autonomous vehicle that could
    observe sea life without disturbing it. We came up with the concept of the
    QUAFFLE: the Quad-rotor Underwater Fish Following Low Disturbance Explorer.
  </>
);

const seniorDesignBulletList = [
  "Guided the team through the design process, covering requirements-gathering, ideation, and concept selection.",
  "Developed an object tracking algorithm using OpenCV in both C and Python and benchmarked algorithm performance.",
  "Designed, 3D printed, and characterized performance of vehicle propellers.",
];

export default function Projects({ mobile }: { mobile?: boolean }) {
  if (mobile)
    return (
      <div id="projects" className="w-full pt-20">
        <div className="text-2xl border-b-2 border-dark w-1/2 mb-6">
          Projects
        </div>
        <div className="flex flex-col w-full pt-4 px-4 gap-y-10 items-center">
          <ProjectTile
            mobile
            id="thesis"
            title="Master's Thesis"
            subTitle="A Framework For Generalized Steady State Neural Fluid Simulations"
            description={thesisText}
            bullets={thesisBulletList}
            imagePath="thesis_img.png"
            url="https://github.com/sguerin13/ManiFlowNet"
          />
          <ProjectTile
            mobile
            id="feature-detector"
            title="CAD Feature Detection"
            subTitle="Demo App for ML Inference on BREP Geometry"
            imagePath="feature_cube.png"
            bullets={cadFeatureDetectionBulletList}
            description={cadFeatureDetectionText}
            url="https://feature-detector.steveguerin.com"
          />
          <ProjectTile
            mobile
            id="senior-design"
            title="Senior Design"
            subTitle="Quad Rotor AUV for Observing Marine Life"
            bullets={seniorDesignBulletList}
            description={seniorDesignText}
            imagePath="quaffle.png"
            url="https://sites.google.com/a/eng.ucsd.edu/156b-2016-spring-team13/home"
          />
        </div>
      </div>
    );
  return (
    <div id="projects" className="w-full pt-[140px]">
      <div className="text-4xl border-b-4 pb-3 border-dark w-64 mb-10">
        Projects
      </div>
      <div className="flex flex-col w-full pt-4 px-4 gap-y-10">
        <ProjectTile
          id="thesis"
          title="Master's Thesis"
          subTitle="A Framework For Generalized Steady State Neural Fluid Simulations"
          description={thesisText}
          bullets={thesisBulletList}
          imagePath="thesis_img.png"
          url="https://github.com/sguerin13/ManiFlowNet"
        />
        <ProjectTile
          id="feature-detector"
          title="CAD File Feature Detection"
          subTitle="Demo App for ML Inference on BREP Geometry"
          imagePath="feature_cube.png"
          bullets={cadFeatureDetectionBulletList}
          description={cadFeatureDetectionText}
          url="https://feature-detector.steveguerin.com"
        />
        <ProjectTile
          id="senior-design"
          title="Undergraduate Senior Design"
          subTitle="Quad Rotor AUV"
          bullets={seniorDesignBulletList}
          description={seniorDesignText}
          imagePath="quaffle.png"
          url="https://sites.google.com/a/eng.ucsd.edu/156b-2016-spring-team13/home"
        />
      </div>
    </div>
  );
}

function ProjectTile(props: {
  mobile?: boolean;
  title: string;
  id: string;
  imagePath?: string;
  description?: string | React.ReactNode;
  bullets: string[];
  subTitle?: string;
  url?: string;
}) {
  const [showDesc, setShowDesc] = React.useState(false);

  if (props.mobile) {
    return (
      <div
        id={props.id}
        className="px-8 py-6 min-h-60 w-[90%] bg-light border-[#eeeeee] border-2 hover:border-1 rounded-lg shadow-md relative scroll-mt-[120px]"
      >
        <a
          className="absolute top-7 right-4"
          target="_blank"
          href={props.url}
          rel="noreferrer"
        >
          <ExternalLinkIcon
            className="h-5 w-5 hover:text-semiDark text-medium"
            strokeWidth={2}
          />
        </a>
        <div className="flex flex-col items-center gap-y-4">
          <div className="text-xl px-[10%] text-center">{props.title}</div>
          <img src={props.imagePath} alt="project icon" width="100px" />

          <div className="text-lg font-light text-semiDark pt-2">
            {props.subTitle}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      id={props.id}
      className="px-6 py-6 min-h-60 bg-light border-[#eeeeee] border-2 hover:border-1 rounded-lg shadow-md hover:shadow-lg hover:scale-[103%] duration-100 scroll-mt-[140px] relative"
    >
      <a
        className="absolute top-7 right-6"
        target="_blank"
        href={props.url}
        rel="noreferrer"
      >
        <ExternalLinkIcon
          className="h-5 w-5 z-50 hover:text-semiDark text-medium"
          strokeWidth={2}
        />
      </a>
      <div className="flex items-center gap-x-10">
        <img src={props.imagePath} alt="project icon" width="100px" />
        <div className="flex flex-col h-full">
          <div className="text-2xl">{props.title}</div>
          <div className="text-lg font-light text-semiDark pt-2">
            {props.subTitle}
          </div>
          <div className="pt-4">{props.description}</div>
        </div>
      </div>
      <div className="pl-36 pt-4">
        <div
          className="text-medium cursor-pointer hover:underline hover:text-semiDark flex items-center gap-x-2"
          onClick={() => setShowDesc(!showDesc)}
        >
          <div>Details</div>
          <div>
            {showDesc ? (
              <ChevronDownIcon
                className="h-[14px] w-[14px] text-medium"
                strokeWidth={2}
              />
            ) : (
              <ChevronRightIcon
                className="h-[14px] w-[14px] text-medium"
                strokeWidth={2}
              />
            )}
          </div>
        </div>
        <ul
          className={` list-disc ml-8 pt-4 font-light ${
            showDesc ? "visible" : "hidden"
          }`}
        >
          {props.bullets.map((bullet, i) => (
            <li className={i !== 0 ? "pt-2" : undefined}>{bullet}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
