export default function Contact({ mobile }: { mobile?: boolean }) {
  if (mobile) {
    return (
      <div
        id="contact"
        className="flex flex-col w-full pt-20 mb-80"
      >
        <div className="text-2xl border-b-2 border-dark w-1/2 mb-10">
          Get in Touch
        </div>
        <div className="flex justify-center items-center gap-x-10">
          <a href="https://github.com/sguerin13">
            <img src="github-mark.svg" alt="github logo" className="w-12" />
          </a>
          <div className="font-semibold">
            <div>steve.guerin13</div>
            <div>@ gmail.com</div>
          </div>
          <a href="https://linkedin.com/in/steveguerin">
            <img src="linkedin-with-circle.svg"  alt="linkedin logo" className="w-12" />
          </a>
        </div>
      </div>
    );
  }

  return (
    <div id="contact" className="min-h-[970px] pt-[140px] flex flex-col w-full">
      <div className="text-4xl border-b-4 pb-3 border-dark w-64 mb-10">
        Get in Touch
      </div>
      <div className="flex justify-center items-center gap-x-20 pt-4">
        <a href="https://github.com/sguerin13">
          <img src="github-mark.svg" alt="github logo" className="w-16" />
        </a>
        <div className="font-semibold">
          <div>steve.guerin13</div>
          <div>@ gmail.com</div>
        </div>
        <a href="https://linkedin.com/in/steveguerin">
          <img src="linkedin-with-circle.svg" alt="linkedin logo" className="w-16" />
        </a>
      </div>
    </div>
  );
}
