export default function HorizontalNav({ mobile }: { mobile?: boolean }) {
  if (mobile)
    return (
      <div className="max-h-[80px] h-[80px] pt-[20px] sticky top-0 bg-light w-full px-4 z-[999]">
        <div className="text-lg py-2 border-b-2 border-dark items-end">
          STEVE GUERIN
        </div>
      </div>
    );

  return (
    <div className="max-h-[120px] h-[120px] pt-[40px] sticky top-0 bg-light w-full flex justify-center z-[999]">
      <div className="w-[840px] mx-4">
        <div className="flex py-2 border-b-2 border-dark items-end">
          <div className="text-xl basis-1/2">STEVE GUERIN</div>
          <div className="flex gap-x-4 text-md basis-1/2 justify-end">
            <div
              onClick={() => {
                document.getElementById("content")?.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}
              className="hover:underline hover:cursor-pointer"
            >
              About
            </div>
            <div
              onClick={() => {
                document
                  .getElementById("experience")
                  ?.scrollIntoView({ behavior: "smooth", block: "start" });
              }}
              className="hover:underline hover:cursor-pointer"
            >
              Experience
            </div>
            <div
              onClick={() => {
                document
                  .getElementById("education")
                  ?.scrollIntoView({ behavior: "smooth", block: "start" });
              }}
              className="hover:underline hover:cursor-pointer"
            >
              Education
            </div>
            <div
              onClick={() => {
                document
                  .getElementById("projects")
                  ?.scrollIntoView({ behavior: "smooth", block: "start" });
              }}
              className="hover:underline hover:cursor-pointer"
            >
              Projects
            </div>
            <div
              onClick={() => {
                document
                  .getElementById("contact")
                  ?.scrollIntoView({ behavior: "smooth", block: "start" });
              }}
              className="hover:underline hover:cursor-pointer"
            >
              Contact
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
