import classNames from "classnames";
import React from "react";

const WaylandDescription = () => (
  <div>
    Full stack role on a small team handling everything from discovery through
    deployment. Developed multiple features integrating AI and created a 3D CAD
    file viewer converting STEP files to browser-friendly Three.js meshes.
  </div>
);

const Link3dDescription = () => (
  <div>
    Quickly built PoC applications integrating new AI and CG technologies for
    Additive Manufacturing workflows. Operated as the point person for the
    Link3D-Materialise app integration and also led the hiring process for
    junior engineers and interns.
  </div>
);

const ArcDescription = () => (
  <div>
    Built out the business processes and managed day-to-day operations for the
    new general prototyping business unit, from sales to delivery. Developed
    internal tools to speed up quoting and design processes by factors of 100 to
    1000x.
  </div>
);

const ApprovedNetworksDescription = () => (
  <div>
    Oversaw fulfillment operations and managed a team of 10+ employees. Led
    continuous improvement efforts and quality initiatives that yielded an 80%
    reduction in errors during a period of 50% YoY growth.
  </div>
);

export default function Experience({ mobile }: { mobile?: boolean }) {
  if (mobile) {
    return (
      <div id="experience" className="flex flex-col gap-y-4 w-full pt-20 px-4">
        <div className="text-2xl border-b-2 border-dark w-1/2 mb-6">
          Experience
        </div>
        <ResumeComponent
          mobile
          date="2022 - 2024"
          title1="Founding Engineer"
          company="Wayland Systems"
          companyDesc="ERP for the CNC Industry"
        >
          <WaylandDescription />
        </ResumeComponent>
        <ResumeComponent
          mobile
          className="pt-4"
          date="2021 - 2022"
          title1="R&D Engineer"
          company="Link3D (Materialise)"
          companyDesc="ERP for Enterprise 3D Printing"
        >
          <Link3dDescription />
        </ResumeComponent>
        <ResumeComponent
          mobile
          className="pt-4"
          date="2018 - 2020"
          title1="Operations Director"
          company="Additive Rocket Corporation"
          companyDesc="Metal 3D Printing for Aerospace"
        >
          <ArcDescription />
        </ResumeComponent>
        <ResumeComponent
          mobile
          className="pt-4"
          date="2017 - 2018"
          date2="2016 - 1017"
          title1="Operations Director"
          title2="Operations Manager"
          company="Approved Networks (Legrand)"
          companyDesc="Network Hardware"
        >
          <ApprovedNetworksDescription />
        </ResumeComponent>
      </div>
    );
  }

  return (
    <div id="experience" className="flex flex-col gap-y-4 w-full pt-[140px]">
      <div className="text-4xl border-b-4 pb-3 border-dark w-64 mb-10">
        Experience
      </div>
      <ResumeComponent
        date="2022 - 2024"
        title1="Founding Engineer"
        company="Wayland Systems"
        companyDesc="ERP for the CNC Industry"
      >
        <WaylandDescription />
      </ResumeComponent>
      <ResumeComponent
        className="pt-6"
        date="2021 - 2022"
        title1="R&D Engineer"
        company="Link3D (Materialise)"
        companyDesc="ERP for Enterprise 3D Printing"
      >
        <Link3dDescription />
      </ResumeComponent>
      <ResumeComponent
        className="pt-6"
        date="2018 - 2020"
        title1="Operations Director"
        company="Additive Rocket Corporation"
        companyDesc="Metal 3D Printing for Aerospace"
      >
        <ArcDescription />
      </ResumeComponent>
      <ResumeComponent
        className="pt-6"
        date="2016 - 2018"
        title1="Operations Director"
        title2="Operations Manager"
        company="Approved Networks (Legrand)"
        companyDesc="Network Hardware"
      >
        <ApprovedNetworksDescription />
      </ResumeComponent>
    </div>
  );
}

function ResumeComponent(props: {
  mobile?: boolean;
  date: string;
  date2?: string;
  title1: string;
  title2?: string;
  company: string;
  companyDesc?: string;
  children: React.ReactNode;
  className?: string;
}) {
  if (props.mobile) {
    return (
      <div className={classNames("w-full", props.className)}>
        <div className="flex flex-col w-full">
          <div className="flex justify-between items-baseline">
            <div className="text-xl">{props.title1}</div>
            <div>{props.date}</div>
          </div>
          {props.title2 && props.date2 && (
            <>
              <div className="flex justify-between items-baseline">
                <div className="text-xl">{props.title2}</div>
                <div>{props.date2}</div>
              </div>
            </>
          )}

          <div className="text-semiDark pt-1.5 flex gap-x-3 items-center">
            <div>{props.company}</div>
          </div>
          <div className="font-light pt-1">{props.children}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames("w-full", props.className)}>
      <div className="flex gap-x-20">
        <div className="text-lg pt-0.5 min-w-24 max-w-24 text-nowrap">{props.date}</div>
        <div className="flex flex-col">
          <div className="text-2xl flex gap-x-3 items-center">
            <div>{props.title1}</div>
            {props.title2 && (
              <>
                <div className="font-light">|</div>
                <div>{props.title2}</div>
              </>
            )}
          </div>
          <div className="text-lg font-light text-semiDark pt-2 flex gap-x-3 items-center">
            <div>{props.company}</div>
            {props.companyDesc && (
              <>
                <div className="font-light">|</div>
                <div>{props.companyDesc}</div>
              </>
            )}
          </div>
          <div className="text-base pt-2">{props.children}</div>
        </div>
      </div>
    </div>
  );
}
