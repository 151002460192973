import classNames from "classnames";
import { useEffect, useState } from "react";
import { ReactTyped } from "react-typed";
export default function About({ mobile }: { mobile?: boolean }) {
  const windowHeight = window.innerHeight;
  const [showCursor, setShowCursor] = useState(true);
  const [mount, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (mobile) {
    return (
      <div
        style={{ minHeight: windowHeight - 260 }}
        id="about"
        className={classNames(
          "pt-12 transition-opacity duration-1000 ease-in-out px-4",
          mount ? "opacity-100" : "opacity-0"
        )}
      >
        <div>
          <div className="flex justify-center">
            <img
              src="/portfolio-gray-rotated.png"
              width="40%"
              alt="Steve Guerin"
            />
          </div>
          <div className="pt-10">
            <MyNameIsSteve
              mobile
              showCursor={showCursor}
              setShowCursor={setShowCursor}
            />
          </div>
        </div>
        <div>
          <div className="text-lg pt-6 text-dark">
            Throughout my career I've been fortunate to work in a variety of
            roles in different industries. The combination of experience along
            with my education in Mechanical Engineering and Computer Science
            allows me to operate at the intersection of 3D Design,
            Manufacturing, Software, and AI.
          </div>
          <div className="text-lg pt-4 text-dark">
            I believe AI has the potential to greatly improve our lives and I am
            driven by the desire to create products and tools that augment human
            creativity and productivity - with a specific emphasis on geometric
            applications.
          </div>
          <div className="text-lg pt-4 text-dark">
            I am currently based in Hoboken, NJ (NYC Metro) and I am always
            looking to connect with people who share my passion for creating and
            trying to improve the lives of those around us. If you'd like to{" "}
            <span
              className="hover:underline hover:cursor-pointer text-[#457dd2]"
              onClick={() => {
                document
                  .getElementById("contact")
                  ?.scrollIntoView({ behavior: "smooth", block: "start" });
              }}
            >
              connect
            </span>
            , please reach out!
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ minHeight: windowHeight - 260 }}
      id="about"
      className={classNames(
        "pt-12 transition-opacity duration-1000 ease-in-out",
        mount ? "opacity-100" : "opacity-0"
      )}
    >
      <div className="w-full flex items-center gap-x-8">
        <div className="w-[65%] flex flex-col pt-8">
          <MyNameIsSteve
            showCursor={showCursor}
            setShowCursor={setShowCursor}
          />
        </div>
        <div className="w-[35%]">
          <img
            src="/portfolio-gray-rotated.png"
            width="256px"
            alt="Steve Guerin"
          />
        </div>
      </div>
      <div className="text-2xl pt-12 text-dark">
        Throughout my career I've been fortunate to work in a variety of roles
        in different industries. The combination of experience along with my
        education in Mechanical Engineering and Computer Science allows me to
        operate at the intersection of 3D Design, Manufacturing, Software, and
        AI.
      </div>
      <div className="text-2xl pt-4 text-dark">
        I believe AI has the potential to greatly improve our lives and I am
        driven by the desire to create products and tools that augment human
        creativity and productivity - with a specific emphasis on geometric
        applications.
      </div>
      <div className="text-2xl pt-4 text-dark">
        I am currently based in Hoboken, NJ (NYC Metro) and I am always looking
        to connect with people who share my passion for creating and trying to
        improve the lives of those around us. If you'd like to{" "}
        <span
          className="hover:underline hover:cursor-pointer text-[#457dd2]"
          onClick={() => {
            document
              .getElementById("contact")
              ?.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        >
          connect
        </span>
        , please reach out!
      </div>
    </div>
  );
}

function MyNameIsSteve({
  mobile,
  showCursor,
  setShowCursor,
}: {
  mobile?: boolean;
  showCursor: boolean;
  setShowCursor: (show: boolean) => void;
}) {
  return (
    <>
      <div
        className={classNames(
          "text-dark",
          mobile ? "leading-none text-4xl" : "text-6xl"
        )}
      >
        Hi, I'm Steve
      </div>
      <div
        className={classNames(
          "flex text-dark",
          mobile ? "pt-4 text-2xl" : "pt-6 text-4xl"
        )}
      >
        {showCursor ? (
          <div>
            <ReactTyped
              typeSpeed={50}
              backSpeed={20}
              strings={[
                "^1000",
                "I'm a^2000n AI Engineer",
                "I'm a Full Stack Dev",
                "I'm a Mechanical Engineer",
                "I'm a . . . ^1250",
                "I'll build it^1250",
              ]}
              smartBackspace
              shuffle={false}
              backDelay={1000}
              loopCount={0}
              showCursor
              onComplete={() => setShowCursor(false)}
              cursorChar="_"
            />
          </div>
        ) : (
          <div>I'll build it</div>
        )}
      </div>
    </>
  );
}
