import About from "./Components/About";
import Contact from "./Components/Contact";
import HorizontalNav from "./Components/HorizontalNav";
import Projects from "./Components/Projects";
import Experience from "./Components/Experience";
import Education from "./Components/Education";
import MediaQuery from "react-responsive";

function App() {
  return (
    <>
      <MediaQuery maxWidth={768}>
        <MobilePage/>
      </MediaQuery>
      <MediaQuery minWidth={769}>
        <DefaultPage />
      </MediaQuery>
    </>
  );
}

function DefaultPage() {
  return (
    <div className="h-screen w-screen bg-light flex justify-center overflow-none">
      <div
        id="content"
        className="text-dark w-full flex flex-col items-center overflow-y-auto"
      >
        <HorizontalNav />
        <div className="max-w-[840px] px-10">
          <About />
          <Experience />
          <Education />
          <Projects />
          <Contact />
        </div>
      </div>
    </div>
  );
}

function MobilePage() {
  return (
    <div className="h-screen w-screen bg-light flex justify-center overflow-none">
      <div
        id="content"
        className="text-dark w-full flex flex-col items-center overflow-y-auto"
      >
        <HorizontalNav mobile />
        <div className="px-4">
          <About mobile />
          <Experience mobile />
          <Education mobile/>
          <Projects mobile />
          <Contact mobile/>
        </div>
      </div>
    </div>
  );
}

export default App;
