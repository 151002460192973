import classNames from "classnames";
import React from "react";

const GradSchoolDescription = () => (
  <div>
    Curriculum covered various topics in algorithms, machine learning, computer
    vision, and geometric deep learning. Served as the TA for undergraduate
    intro computer vision and undergraduate mechanical engineering
    senior design.{" "}
    <span
      className="hover:underline hover:cursor-pointer text-[#457dd2]"
      onClick={() => {
        document
          .getElementById("thesis")
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
      }}
    >
      Master's thesis
    </span>{" "}
    involved the creation of the world's first geometry-agnostic neural fluid
    simulation framework built on top of the Ansys simulation suite.
  </div>
);

const UndergradDescription = () => (
  <div>
    Outside of the curriculum, was involved with projects and organizations
    involving product development, entrepreneurship, and additive manufacturing.
    These include the Students for the Exploration and Development of Space
    (SEDS),the Gordon Engineering Leadership Program, and founding the Triton 3D Printing Club. The{" "}
    <span
      className="hover:underline hover:cursor-pointer text-[#457dd2]"
      onClick={() => {
        document
          .getElementById("senior-design")
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
      }}
    >
      senior design project
    </span>{" "}
    involved the design and creation of an underwater autonomous vehicle.
  </div>
);

export default function Education({ mobile }: { mobile?: boolean }) {
  if (mobile) {
    return (
      <div id="education" className="flex flex-col gap-y-4 w-full pt-20 px-4">
        <div className="text-2xl border-b-2 border-dark w-1/2 mb-6">
          Education
        </div>
        <EducationComponent
          mobile
          className=""
          date="2023"
          degree="MS Computer Science"
          subTitle1="UC San Diego"
          subTitle2="AI Concentration"
          subTitle3="3.63 GPA"
        >
          <GradSchoolDescription />
        </EducationComponent>
        <EducationComponent
          mobile
          className="pt-4"
          date="2016"
          degree="BS Mechanical Engineering"
          subTitle1="UC San Diego"
          subTitle2="Magna Cum Laude"
          subTitle3="3.87 GPA"
        >
          <UndergradDescription />
        </EducationComponent>
      </div>
    );
  }

  return (
    <div id="education" className="flex flex-col gap-y-4 w-full pt- pt-[140px]">
      <div className="text-4xl border-b-4 pb-3 border-dark w-64 mb-10">
        Education
      </div>
      <EducationComponent
        className=""
        date="2023"
        degree="MS Computer Science"
        subTitle1="UC San Diego"
        subTitle2="AI Concentration"
        subTitle3="3.63 GPA"
      >
        <GradSchoolDescription />
      </EducationComponent>
      <EducationComponent
        className="pt-6"
        date="2016"
        degree="BS Mechanical Engineering"
        subTitle1="UC San Diego"
        subTitle2="Magna Cum Laude"
        subTitle3="3.87 GPA"
      >
        <UndergradDescription />
      </EducationComponent>
    </div>
  );
}

function EducationComponent(props: {
  mobile?: boolean;
  date: string;
  degree: string;
  subTitle1: string;
  subTitle2?: string;
  subTitle3?: string;
  children: React.ReactNode;
  className?: string;
}) {
  if (props.mobile) {
    return (
      <div className={classNames("w-full", props.className)}>
        <div className="flex flex-col w-full">
          <div className="flex justify-between items-baseline">
            <div className="text-xl">{props.degree}</div>
            <div>{props.date}</div>
          </div>
          <div className="text-lg text-semiDark pt-1.5 flex gap-x-3 items-center">
            <div>{props.subTitle1}</div>
            {props.subTitle3 && (
              <>
                <div className="font-light">|</div>
                <div>{props.subTitle3}</div>
              </>
            )}
          </div>
          <div className="text-base pt-1">{props.children}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames("w-full", props.className)}>
      <div className="flex gap-x-20">
        <div className="text-lg pt-0.5 min-w-24 max-w-24 text-right">
          {props.date}
        </div>
        <div className="flex flex-col">
          <div className="text-2xl flex gap-x-3 items-center">
            <div>{props.degree}</div>
          </div>
          <div className="text-lg font-light text-semiDark pt-2 flex gap-x-3 items-center">
            <div>{props.subTitle1}</div>
            {props.subTitle2 && (
              <>
                <div className="font-light">|</div>
                <div>{props.subTitle2}</div>
              </>
            )}
            {props.subTitle3 && (
              <>
                <div className="font-light">|</div>
                <div>{props.subTitle3}</div>
              </>
            )}
          </div>
          <div className="text-base pt-2">{props.children}</div>
        </div>
      </div>
    </div>
  );
}
